<template>
    <v-container>
      <v-card>
        <v-data-table class="rounded" :headers="headers" :items="factories" :search.sync="searchPhrase" @click:row="goToDetails" @pagination="testPager">
          <template v-slot:top>
            <v-toolbar flat rounded color="primary" dark>
              <v-toolbar-title>Factories</v-toolbar-title>
              <v-spacer />
              <v-text-field placeholder="Search" v-model="searchPhrase" prepend-inner-icon="mdi-magnify" hide-details filled rounded
                dense></v-text-field>
              <v-spacer />
              <!-- <v-btn text class="primary" :to="{ name: 'add-community' }" rounded>
                <v-icon left>mdi-plus</v-icon>
                Add Community
              </v-btn> -->
              <v-btn icon @click="downloadFactoriesCSV">
                <v-icon color="white">mdi-microsoft-excel</v-icon>
                </v-btn>
            </v-toolbar>
          </template>
  
          <!-- <template v-slot:item.details="{ item }">
            <v-btn
              @click.stop="disableFarmer(item)"
              :loading="item.disabling"
              color="error"
              depressed
              rounded
              block
              :outlined="!item.disabled"
            >
              {{ item.disabled ? "Disabled" : "Disable" }}
            </v-btn>
          </template> -->
        </v-data-table>
      </v-card>
    </v-container>
  </template>
  <script>
  import dayjs from "dayjs";
  import relativeTime from "dayjs/plugin/relativeTime";
  import config from "@/config";
  import firebase from "firebase";
  import { downloadxls } from "../../plugins/downloadxlsx";

  
  
  dayjs.extend(relativeTime);
  export default {
    name: "factories",
    data: () => ({
      searchPhrase: "",
      disabling_farmer: false,
      headers: [
        { align: "center", text: "Name", value: "name" },
        { align: "center", text: "Location", value: "location" }
      ],
      config,
      token: null,
      factories: [],
      downloading: false,
      
    }),
    
    methods: {
      getAge(date) {
        return dayjs(date).fromNow(true);
      },
      goToDetails(factory, options) {
        this.$router.push({
          name: "edit-factory",
          params: { id: factory._id },
        });
      },
      async disableFarmer(item) {
        item.disabling = !item.disabling;
        this.$axios.put(`/factories/${item._id}`, { disabled: !item.disabled });
        item.disabled = !item.disabled;
        item.disabling = !item.disabling;
      },
      downloadFactoriesCSV() {
        this.exportFactories = this.factories.map((factory) => ({
        "_id": factory._id,
        "Name": factory.name,
        "Location": factory.location,
      }));
      downloadxls(this.exportFactories, "factories")
        
      },
    },
    async mounted() {
      const { token, claims } = await firebase
        .auth()
        .currentUser.getIdTokenResult(true);
      this.token = token;
      this.claims = claims;
  
      this.$axios
        .get("/factories")
        .then((res) => {
          this.factories = (res.data || []).map((factories) => ({
            ...factories,
            disabling: false,
          }));
        })
        .catch((err) => {
          console.error(err);
        });
    },
  };
  </script>
  